@import '@popsure/dirty-swan/dist/grid';

.container {
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr;

  @include p-size-mobile {
    grid-template-columns: 1fr;
  }

  a {
    text-decoration: none;
  }
}
