@import '~@popsure/dirty-swan/dist/grid';

.cardWrapper {
  & img {
    transform: scale(0.9);
    transition: 0.6s cubic-bezier(0.21, 0.81, 0.42, 1.03);
  }
}

.cardWrapper:hover {
  & img {
    transform: scale(1);
  }
}

.img {
  height: 90px;
  width: 90px;
}

.imgWrapper {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 16px;
  position: relative;
}

.content {
  flex: 1;
}

.label {
  position: absolute;
  bottom: 4px;
  right: 4px;
  padding: 4px 8px;
}

.companyTag {
  margin-left: 4px;
}
